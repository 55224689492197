import * as React from "react"
import Accordeons from "src/components/accordeons"
import Footer from "src/components/footer"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import TwoCols from "src/components/twoCols"
import footerData from "src/data/global/footer"
import { serviceAtf, serviceMeta, serviceProjects, serviceServices } from "src/data/pages/services/markup"

const IndexPage = () => (
  <Layout>
    <Seo title={serviceMeta.title} description={serviceMeta.description} url="/services" />
    <TwoCols data={serviceAtf} />
    <Accordeons data={serviceServices} />
    <TwoCols data={serviceProjects} />
    <Footer data={footerData} />
  </Layout>
)

export default IndexPage
