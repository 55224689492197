export const serviceMeta = {
  title: "Meine Leistungen",
  description: "Ein Überblick über meine Leistungen? Kein Problem! Ich biete unterschiedliche Tätigkeiten als Fullstack-Web-Entwickler und Berater an. Melde dich bei mir!"
}

export const serviceAtf = {
  imageLeft: false,
  imageCenter: false,
  imageColWidth: 6,
  image: "transparent-1",
  isAtf: true,
  alt: "Image of me, sitting on a chair",
  hasBackground: true,
  heading: "<strong>Meine Leistungen</strong>",
  text: "<p>Als Webentwickler konnte ich schon in vielen Gebieten Erfahrungen sammeln. Eine gute Weblösung ist für mich schnell, übersichtlich, wartbar und erweiterbar.</p><p>Ich habe bei meinen Projekten deshalb einen sehr hohen Qualitätsanspruch. Ein durchdachtes Konzept, sauberer Code, die genaue Umsetzung der Vorgaben und ein hochwertiges Endprodukt sind mir total wichtig.</p><p>Meine Kundinnen und Kunden freuts :) </p>",
}

export const serviceServices = {
  hasBackground: false,
  heading: "<strong>Was darf es Feines für dich sein?</strong>",
  text: "<p>Hier ein kleiner Überblick über meine Leistungen.</br></br> Nicht das dabei, was du suchst? Melde dich einfach bei mir, wir finden sicher eine Lösung :) </p>",
  accordeons: [
    {
      title: "Website Komplettlösung",
      text: "Gemeinsam mit meinen PartnerInnen erstelle ich deine neue Website. Ich übernehme die Planung und kümmere mich um DesignerIn, TexterIn und FotografIn. Nach deinem Feedback wird die Website auf Basis des Designs von mir entwickelt - mit passender Technologie je nach Wunsch und Budget.",
      readMore: "/services/website",
    },
    {
      title: "WordPress Custom Development",
      text: "<p>Ich erstelle schnelle, saubere und individuelle Themes oder Templates nach Vorlage eines bestehenden Designs.</p><p>Die Funktionen von WordPress reichen nicht aus? Ich erstelle ein individuelles Plugin für dich, welches deinen Business Case ideal abbildet.</p>",
      readMore: "/services/wordpress",
    },
    {
      title: "Jamstack Entwicklung",
      text: "Der Jamstack (Javascript, API, Markup) ist ein moderner Ansatz zur Erstellung von Websites und Webshops. Jamstack-Websites sind unglaublich schnell, sicher und ziemlich flexibel. Dafür dauert die Entwicklung etwas länger. Ich berate dich gerne dazu und kann dein Design auch als Jamstack Website entwicklen.",
      readMore: "/services/jamstack",
    },
    {
      title: "Laravel Backend Entwicklung",
      text: "Manche Dinge sind noch immer komplizierter als sie sein müssten. <strong>Individuelle Backend-Lösungen</strong> können ihren Teil dazu beitragen, die Prozesse in deinem Unternehmen zu <strong>vereinfachen</strong> und zu <strong>automatisierten</strong>.",
      readMore: "/services/backend",
    },
    {
      title: "Freelance Web-Entwickler",
      text: "Du benötigst jemanden, der dir dich bei einem aktuellen Projekt unterstützt oder dir dabei hilft, Anfrage-Spitzen auszugleichen? Dabei kann ich dir weiter helfen.</br></br>Als erfahrener Freelancer kann ich Projekte komplett oder in Teilen übernehmen und so dein Team entlasten.",
      readMore: "/services/freelance",
    },
    {
      title: "Beratung & Support",
      text: "Als Digtal Native biete ich Beratungsgespräche für meine Kunden zu unterschiedlichen Themen an. Sollte auf einer Website einmal etwas nicht wie gewünscht klappen, stehe ich gerne mit Rat und Tat zur Seite.",
      readMore: "/services/beratung",
    },
    {
      title: "Gleich Vorgespräch buchen - kostenlos & unverbindlich!",
      link: "https://calendly.com/dominik-schratl/besprechungen",
    },
  ],
}

export const serviceProjects = {
  imageLeft: true,
  imageCenter: true,
  imageColWidth: 6,
  imagePadding: true,
  image: "home-projects",
  alt: "Screenshot of my Projects",
  hasBackground: true,
  heading: "<strong>Meine letzten Projekte waren … nice!</strong>",
  text: "<p>Hier findest du einen kleinen Überblick über meine letzten Projekte.<p>Als Freelancer kann ich aber leider nicht alles über meine Projekte erzählen. Deshalb habe ich die meine Erfahrungen als Case Studies in meinem Portfolio verarbeitet.</p><p>Dazwischen findest du noch den ein oder anderen Blogbeitrag.</p><p>Portfolio und Blog in einem?</p>",
  button: {
    link: "/blog",
    text: "Zum Blog-Folio :) ",
    isConfetti: false,
  },
}
